.App {
  background-color: #FAF9F6;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  color: #636363;
  overflow-x: hidden;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  pointer-events: none;
  border-radius: 50%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  color: #FAF9F6;
  background-color: #5E0099;
  text-align: left;
  margin-bottom: 0px;
  padding: 5px;
  width: 100%;
}

.section-div {
  width: 100%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-jump {
  width: 100%;
  align-items: right;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 15px;
}

.section-header {
  color: #FAF9F6;
  background-color: #5E0099;
  text-align: center;
  width: 100%;
  padding: 10px;
  align-items: center;
}

.display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  color: #FAF9F6;
  background-color: #5E0099;
  text-align: center;
  margin-bottom: 0px;
  padding: 5px;
  margin-top: 10px;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  padding-left: 5px;
  padding-right: 5px;
}

.App-link {
  color: #636363;
}

.body-link {
  color: #5E0099;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  padding-right: 5px;
}

.footer-link {
  color: #DBBEFF;
  text-decoration: none;

}

.name-header {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}

.hl-header {
  width: 50%;
  background-color: #DBBEFF;
  height: 1px;
  border: none;
}

.hl-body {
  width: 50%;
  background-color: #5E0099;
  height: 1px;
  border: none;
}